import React from 'react'
import './index.css'

const PlayOffMatch = ({ teamA, logoTeamA, teamB, logoTeamB, result, knockOutPhase, matches, played, matchClick }) => {
    let resultArray = result.split("-");
    let resultA = resultArray[0];
    let resultB = resultArray[1];


    let actualLogoTeamA = teamA.replaceAll(' ', '_');
    actualLogoTeamA += ".png";
    let actualLogoTeamB = teamB.replaceAll(' ', '_');
    actualLogoTeamB += ".png";

    if (!played) {
        actualLogoTeamA = "Free_Agent.png";
        actualLogoTeamB = "Free_Agent.png";
    }

    let tmpMatch = {
        knockOutPhase: knockOutPhase,
        matches: matches,
        result: result,
        played: played
    }

    return (
        <div className='playoff-match-container' onClick={() => { matchClick(tmpMatch) }}>
            <div className='row'>
                <div className='logo-box'><img src={`https://competitivefutsal.it/teamLogos/${actualLogoTeamA}`} alt={`${""}`}></img></div>
                <div className='team-name'>{teamA}</div>
                {resultA > resultB ?
                    <div className='score-win'>{resultA}</div>
                    :
                    <div className='score'>{resultA}</div>
                }

            </div>
            <div className='row'>
                <div className='logo-box'><img src={`https://competitivefutsal.it/teamLogos/${actualLogoTeamB}`} alt={`${""}`}></img></div>
                <div className='team-name'>{teamB}</div>
                {resultB > resultA ?
                    <div className='score-win'>{resultB}</div>
                    :
                    <div className='score'>{resultB}</div>
                }
            </div>
        </div>
    )
}

export default PlayOffMatch